<template>
  <div class="app-container">
    <div class="page-title">
      <h2>Le grade</h2>
    </div>

    <!-- <el-card class="box-card"> -->
    <div class="filter-container">
      <!-- <el-button style="margin-left: auto" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="PrintProductor">
        Imprimer
      </el-button> -->
    </div>
    <el-row>
      <el-col :span="7" style="margin-right:7px">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight:bold">Informations du grade</span>
              <!-- <el-button style="float: right; padding: 3px 0" type="text">Bouton</el-button> -->
            </div>
            <div class="text item" style="display: flex; align-items: center; flex-direction:column">
              <!-- <div class="pan-thumb" />
                <span style="font-weight: bold; font-style: italic; margin-bottom: 5px">{{ data.nom + ' ' + data.prenom }}</span>
                <span style="font-style: italic;">{{ data.sexe ==='f'?'Féminin':'Masculin' | uppercaseFirst }}</span> -->
              <el-table v-loading="loading" :data="persinfos" :show-header="false">
                <el-table-column>
                  <template slot-scope="scope" style="background-color: bisque">
                    <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                  </template>
                </el-table-column>
                <el-table-column>
                  <template slot-scope="scope">
                    <span style="font-style: italic;">{{ scope.row.valeur }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="grid-content bg-purple-light">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight: bold;">Détails</span>
            </div>
            <el-tabs tab-position="top">
              <!-- <el-tab-pane label="Organisation">
                  <el-table v-loading="loading" :data="organInfos" :show-header="false">
                    <el-table-column>
                      <template slot-scope="scope" style="background-color: bisque">
                        <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <template slot-scope="scope">
                        <span style="font-style: italic;">{{ scope.row.valeur }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane> -->
              <el-tab-pane label="Contrôleurs">
                <el-table :data="data.controleurs" border fit highlight-current-row style="width: 100%">
                  <el-table-column align="center" label="Code" min-width="110px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.code }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" :label="$t('controleur.nom')" min-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.nom + ' '+ scope.row.prenom }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" :label="$t('controleur.numero')" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.numero }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="Action" min-width="150px">
                    <template slot-scope="scope">
                      <el-button type="primary" size="small" icon="el-icon-view" @click="navigateControleur(scope.row.id)" />
                    </template>
                  </el-table-column>

                  <!-- <el-table-column align="center" :label="$t('producteur.numero')" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.numero }}</span>
                    </template>
                  </el-table-column> -->

                  <!-- <el-table-column align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
                      <template slot-scope="scope">
                        <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateControleur(scope.row.id)" />
                      </template>
                    </el-table-column> -->
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <!-- <el-table v-loading="loading" :data="mininfos" :show-header="false">
        <el-table-column>
          <template slot-scope="scope" style="background-color: bisque">
            <span style="font-weight: bold;">{{ scope.row.name1 }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            {{ scope.row.val1 }}
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <span style="font-weight: bold">{{ scope.row.name2 }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            {{ scope.row.val2 }}
          </template>
        </el-table-column>
      </el-table> -->
    <!-- <table v-if="data !== null" style="border : 1px solid #000000; width:100%" cellspacing="0">
        <tr>
          <td style="border: 1px solid #000;">Code UP</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.code }}</td>
          <td style="border: 1px solid #000;">Nom du producteur</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.nom + ' ' + data.prenom }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Zone</td>
          <td style="border: 1px solid #000; width: 200px;">{{ }}</td>
          <td style="border: 1px solid #000;">Sexe (M/F)</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.sexe | uppercaseFirst }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Commune</td>
          <td style="border: 1px solid #000; width: 200px;">{{ }}</td>
          <td style="border: 1px solid #000;">Groupement</td>
          <td style="border: 1px solid #000; width: 200px;">{{ nomGroupements[data.groupement_id] }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Village</td>
          <td style="border: 1px solid #000; width: 200px;">{{ nomVillages[data.village_id] }}</td>
          <td style="border: 1px solid #000;">Code Producteur</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.code }}</td>
        </tr>
      </table> -->

    <!-- </el-card> -->
    <!-- <h4>Liste des Parcelles du producteur</h4>
    <el-card class="box-card filter-card just-for-admin">
      <el-table :data="parcelles" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="Code" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('parcelle.superficie')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.superficie }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('parcelle.nbr_arb')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.nbr_arb }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template>
            <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateControleur" />
          </template>
        </el-table-column>
      </el-table>
    </el-card> -->
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
// const controleurResource = new Resource('controleurs');
const groupementResource = new Resource('groupements');
// const structureResource = new Resource('structures');
const villageResource = new Resource('villages');
const gradeControleurResource = new Resource('grade_controleurs');
export default {
  name: 'ProducteurInfo',
  // components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      data: null,
      date: '',
      fullscreenLoading: true,
      parcelles: null,
      printing: false,
      list: null,
      total: 0,
      sexe: '',
      image: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      nomVillages: null,
      nomGroupements: null,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
      },
      loading: true,
      downloading: false,
      villages: null,
      groupements: null,
      activeList: 'disponibles',
      producteurCreating: false,
      editing: false,
      persinfos: null,
      organInfos: null,
      currentProducteur: {},
      dialogCreateProducteur: false,
      currentProducteurId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('producteur.NomRequired'), trigger: 'blur' }],
        prenom: [{ required: true, message: this.$t('producteur.PrenomRequired'), trigger: 'blur' }],
        numero: [{ required: true, message: this.$t('producteur.NumeroRequired'), trigger: 'blur' }],
        adresse: [{ required: true, message: this.$t('producteur.AdresseRequired'), trigger: 'blur' }],
      },
      fileList: [],
      mininfos: [],
    };
  },
  computed: {

  },
  created() {
    this.getGroupementsList();
    this.getVillagesList();
    this.getGrade();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    async getGroupementsList() {
      const { data } = await groupementResource.list(this.query);
      this.groupements = data;
      var _reg = {};
      data.forEach(groupement => {
        _reg[groupement.id] = groupement.nom;
      });
      this.nomGroupements = _reg;
    },
    PrintProductor(){
      var routeData = this.$router.resolve({ path: '/producteur/' + this.$route.params.id });
      window.open(routeData.href, '_blank');
    },
    navigateImpression(){
      var routeData = this.$router.resolve({ path: '/parcelle/' + this.$route.params.id });
      window.open(routeData.href, '_blank');
    },
    navigateControleur(id){
      this.$router.push({ path: '/controleur/' + id });
    },
    async getVillagesList() {
      const { data } = await villageResource.list(this.query);
      this.villages = data;
      var _reg = {};
      data.forEach(village => {
        _reg[village.id] = village.nom;
      });
      this.nomVillages = _reg;
    },
    async getGrade() {
      // const { data } = await producteursResource.get(this.$route.params.id);
      const { data } = await gradeControleurResource.get(this.$route.params.id);
      this.data = data;
      console.log('leproduitttttttttt : ', this.data);
      this.fullscreenLoading = false;
      console.log('le loading : ', this.fullscreenLoading);
      this.date = moment().format('DD-MM-YYYY');
      // this.organInfos = [
      //   {
      //     'libelle': 'Code UP    :',
      //     'valeur': data.unite_production !== null ? data.unite_production.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Zone    :',
      //     'valeur': data.zone !== null ? data.zone.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Commune    :',
      //     'valeur': data.commune !== null ? data.commune.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Groupement',
      //     'valeur': data.groupement !== null ? data.groupement.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Village',
      //     'valeur': data.village !== null ? data.village.nom : 'INDISPONIBLE',
      //   },
      // ];
      this.persinfos = [
        {
          'libelle': 'Nom    :',
          'valeur': data.nom,
        },
        {
          'libelle': 'Description    :',
          'valeur': data.description,
        },
      ];
      // this.mininfos = [
      //   {
      //     'name1': 'Code UP',
      //     'val1': data.unite_production !== null ? data.unite_production.nom : 'INDISPONIBLE',
      //     'name2': 'Nom du producteur',
      //     'val2': data.nom + ' ' + data.prenom,
      //   },
      //   {
      //     'name1': 'Zone',
      //     'val1': data.zone !== null ? data.zone.nom : 'INDISPONIBLE',
      //     'name2': 'Sexe (M/F)',
      //     'val2': data.sexe,
      //   },
      //   {
      //     'name1': 'Commune',
      //     'val1': data.commune !== null ? data.commune.nom : 'INDISPONIBLE',
      //     'name2': 'Groupement',
      //     'val2': data.groupement !== null ? data.groupement.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'name1': 'Village',
      //     'val1': data.village !== null ? data.village.nom : 'INDISPONIBLE',
      //     'name2': 'Code Producteur',
      //     'val2': data.code,
      //   },
      // ];
      this.loading = false;
      // console.log('tableau : ', this.mininfos);
      // if (data !== null) {
      //   window.print();
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.pan-thumb {
  width: 150px;
  height: 150px;
  background-position: center center;
  background-size: cover;
  border-radius: 75px;
  overflow: hidden;
  margin-bottom: 20px;
  // position: absolute;
  background-image:url('https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg');
  // transform-origin: 95px 40px;
  // transition: all 0.3s ease-in-out;
  background-repeat:no-repeat;
}
</style>
